<template>
  <v-app-bar app color="white" elevate-on-scroll elevation="4">
    <v-app-bar-nav-icon @click="$emit('handeDrawer')"></v-app-bar-nav-icon>

    <v-toolbar-title>TFL CMS</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon>mdi-heart</v-icon>
    </v-btn>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <!-- <v-badge offset-y="10" offset-x="10" content="5"> -->
          <v-icon>mdi-dots-vertical</v-icon>
          <!-- </v-badge> -->
        </span>
      </template>
      <v-list two-line>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo"> mdi-account </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <router-link to="profile">
              <v-list-item-title>Profile</v-list-item-title>
              <v-list-item-subtitle>User Account Settings</v-list-item-subtitle>
            </router-link>
          </v-list-item-content>

          <!-- <v-list-item-icon>
            <v-icon>mdi-message-text</v-icon>
          </v-list-item-icon> -->
        </v-list-item>

        <!-- <v-list-item>
          <v-list-item-action></v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>(323) 555-6789</v-list-item-title>
            <v-list-item-subtitle>Work</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon>mdi-message-text</v-icon>
          </v-list-item-icon>
        </v-list-item> -->

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="red"> mdi-power </v-icon>
          </v-list-item-icon>

          <v-list-item-content @click="logout" style="cursor: pointer">
            <v-list-item-title>Logout</v-list-item-title>
            <!-- <v-list-item-subtitle>Orlando, FL 79938</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import store from "../../store/modules/user.js";
export default {
  data: () => ({
    user_id: "",
  }),
  methods: {
    logout() {
      store.dispatch("logout").then((response) => {
        if (response.data[0] == 200) {
          this.$router.push({ name: "Login" });
        }
      });
    },
  },
  created() {
    this.user_id = store.state.user.data.id;
  },
};
</script>

<style lang="scss" scoped></style>
