import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store/index.js'
import token from './store/modules/user.js'
import axios from 'axios'
import CKEditor from 'ckeditor4-vue';

axios.defaults.headers = {
  "Authorization":`Bearer ${token.state.user.token}`,
  "Accept":'application/json'
}

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';
axios.defaults.baseURL = 'https://backend.trainingforlifetz.org/api/';

// Vue.prototype.$backendUrl = 'http://127.0.0.1:8000/';
Vue.prototype.$backendUrl = 'https://backend.trainingforlifetz.org/';

Vue.config.productionTip = false

Vue.use( CKEditor );

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
