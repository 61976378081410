<template>
  <div>
    <div v-if="loading">
      <Preloader />
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            label="Title"
            v-model="event.title"
            required
            outlined
          ></v-text-field>
          <div
            v-if="errors.title"
            v-html="errors.title[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <ckeditor v-model="event.description"></ckeditor>
          <div
            v-if="errors.description"
            v-html="errors.description[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-file-input
            outlined
            truncate-length="15"
            label="Select Image (s)"
            @change="set_event_images"
            accept="image/*"
            multiple
          ></v-file-input>
          <div
            v-if="errors.images"
            v-html="errors.images[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="3" v-for="image in images" :key="image.id">
          <div class="news-image">
            <v-img
              :lazy-src="$backendUrl + image.image_url"
              :src="$backendUrl + image.image_url"
            ></v-img>
            <v-btn
              class="mx-2 delete-btn"
              fab
              dark
              small
              @click="delete_news_image(image.id)"
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="12">
          <v-btn block :loading="submit" @click="update_news_events">
            Save
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import Preloader from "../universal/Preloader.vue";
export default {
  components: {
    Preloader,
  },
  data: () => ({
    errors: [],
    loading: true,
    submit: false,
    event_id: null,
    images: [],
    event: {
      title: "",
      description: "",
      images: [],
    },
  }),
  methods: {
    edit_news() {
      axios
        .get("edit-news/" + this.event_id)
        .then((response) => {
          this.event.title = response.data.title;
          this.event.description = response.data.description;
          this.images = response.data.news_images;
          this.loading = false;
        })
        .catch((error) => {
          swal(
            "Oops!",
            "Sorry something went wrong, please try again!",
            "error"
          );
          return error;
        });
    },
    set_event_images(event) {
      for (let i = 0; i < event.length; i++) {
        let file = event[i];
        let render = new FileReader();
        render.onloadend = () => {
          this.event.images.push({ image: render.result });
        };
        render.readAsDataURL(file);
      }
    },
    update_news_events() {
      this.errors = [];
      this.submit = "loading";
      axios
        .patch("update-news-event/" + this.event_id, this.event)
        .then((response) => {
          switch (response.data[0]) {
            case 200:
              this.event.title = "";
              this.event.description = "";
              this.event.images = [];
              this.edit_news();
              this.errors = [];
              this.submit = false;
              swal("Good job!", "Updated successfully!", "success");
              break;
            default:
              swal(
                "Oops!",
                "Sorry something went wrong, please try again!",
                "error"
              );
              this.submit = false;
              break;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.submit = false;
          swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
          return error;
        });
    },
    delete_news_image(image_id) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this image file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .get("delete-news-image/" + image_id)
            .then((response) => {
              switch (response.data[0]) {
                case 200:
                  this.edit_news();
                  swal("Poof! Your image file has been deleted!", {
                    icon: "success",
                  });
                  break;
              }
            })
            .catch((error) => {
              swal(
                "Oops!",
                "Sorry something went wrong, please try again!",
                "error"
              );
              return error;
            });
        } else {
          swal("Your image file is safe!");
        }
      });
    },
  },
  created() {
    this.event_id = this.$route.params.id;
    this.edit_news();
  },
};
</script>

<style scoped>
.news-image {
  position: relative;
}
.delete-btn {
  top: 0;
  right: 0;
  position: absolute;
}
</style>
