<template>
  <v-navigation-drawer v-model="drawer" app>
    <v-sheet color="grey lighten-4" class="pa-4">
      <v-avatar class="mb-4" color="grey darken-1" size="64"></v-avatar>

      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ user.data.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ user.data.email }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-sheet>

    <v-divider></v-divider>

    <v-list-item-group v-model="selectedItem" color="primary">
      <v-list-item v-for="[path, icon, text] in links" :key="icon" link>
        <router-link :to="path" style="display: flex; text-decoration: none">
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </router-link>
      </v-list-item>
    </v-list-item-group>
  </v-navigation-drawer>
</template>

<script>
import store from "../../store/modules/user.js";
export default {
  props: ["drawer"],
  data: () => ({
    user: [],
    selectedItem: null,
    links: [
      ["/dashboard", "mdi-speedometer", "Dashboard"],
      ["/staffs", "mdi-account-multiple", "Staffs"],
      ["/add-alumni", "mdi-account-school", "Add Alumni"],
      ["/alumnis", "mdi-account-group", "Alumnis"],
      ["/add-news-event", "mdi-newspaper-plus", "Add News / Events"],
      [
        "/news-event",
        "mdi-newspaper-variant-multiple-outline",
        "News and Events",
      ],
      ["students-applications", "mdi-format-list-numbered", "Applications"],
    ],
  }),
  created() {
    this.user = store.state.user;
  },
};
</script>

<style lang="stylus" scoped></style>
