<template>
  <div>
    <div v-if="loading">
      <Preloader />
    </div>
    <div v-else>
      <div style="margin-top: 5px; right: 0">
        <v-fab-transition>
          <v-btn elevation="2" fab icon right top @click="add_staff_dialog">
            <v-icon>mdi-account-multiple-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </div>
      <div
        style="margin-top: 2rem"
        v-for="(staff, index) in staffs"
        :key="staff.id"
        :ref="`staff-${index}`"
        @dragstart="onDragStart(index)"
        @dragover="onDragOver"
        @drop="onDrop($event, index)"
        draggable="true"
      >
        <v-card elevation="2">
          <v-card-title class="d-block">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div>
                {{ staff.name }} <br />
                <small>{{ staff.role.name }}</small>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: right;
                  align-items: center;
                "
              >
                <div class="float-right">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    @click="edit_staff_dialog(staff)"
                  >
                    <v-icon dark> mdi-pencil </v-icon>
                  </v-btn>
                </div>

                <div class="float-right">
                  <v-switch
                    v-model="staff.status"
                    @change="publish_unpublish_staff(staff.id)"
                    inset
                  ></v-switch>
                </div>
              </div>
            </div>
          </v-card-title>
        </v-card>
      </div>

      <!-- Dialog for adding and editing staff -->
      <template>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5" v-if="!edit_mode">Add Staff</span>
              <span class="text-h5" v-else>Update Staff</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      label="Full name*"
                      v-model="staff.full_name"
                      required
                      outlined
                    ></v-text-field>
                    <div
                      v-if="errors.full_name"
                      v-html="errors.full_name[0]"
                      style="color: red"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-file-input
                      truncate-length="15"
                      counter
                      label="Image file"
                      @change="set_staff_image"
                      accept="image/*"
                      outlined
                    ></v-file-input>
                    <div
                      v-if="errors.image"
                      v-html="errors.image[0]"
                      style="color: red"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      :items="roles"
                      label="Role / Position*"
                      item-text="name"
                      item-value="id"
                      v-model="staff.role"
                      required
                      outlined
                    ></v-select>
                    <div
                      v-if="errors.role"
                      v-html="errors.role[0]"
                      style="color: red"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="submit"
                @click="edit_mode ? update_staff() : add_new_staff()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import Preloader from "../universal/Preloader.vue";
export default {
  components: {
    Preloader,
  },
  data: () => ({
    errors: [],
    submit: false,
    staff_id: null,
    edit_mode: false,
    loading: true,
    staffs: [],
    staff: {
      full_name: "",
      image: "",
      role: null,
    },
    roles: [],
    dialog: false,
  }),
  methods: {
    get_data() {
      axios
        .get("get-data")
        .then((response) => {
          this.staffs = response.data[0];
          this.roles = response.data[1];
          this.loading = false;
        })
        .catch((error) => {
          swal(
            "Oops!",
            "Sorry something went wrong, please try again!",
            "error"
          );
          return error;
        });
    },
    add_staff_dialog() {
      this.staff.full_name = "";
      this.staff.image = "";
      this.staff.role = null;
      this.edit_mode = false;
      this.errors = [];
      this.dialog = true;
    },
    set_staff_image(event) {
      let file = event;
      let render = new FileReader();
      render.onloadend = () => {
        this.staff.image = render.result;
      };
      render.readAsDataURL(file);
    },
    add_new_staff() {
      this.submit = "loading";
      axios
        .post("add-new-staff", this.staff)
        .then((response) => {
          switch (response.data[0]) {
            case 200:
              this.staff.full_name = "";
              this.staff.image = "";
              this.staff.role = null;
              this.dialog = false;
              this.submit = false;
              swal("Good job!", "Staff added successfully!", "success");
              break;
            default:
              swal(
                "Oops!",
                "Sorry something went wrong, please try again!",
                "error"
              );
              break;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.submit = false;
          swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
          return error;
        });
      this.get_data();
    },
    edit_staff_dialog(staff) {
      this.staff_id = staff.id;
      this.staff.full_name = staff.name;
      this.staff.image = "";
      this.staff.role = staff.role.id;
      this.errors = [];
      this.edit_mode = true;
      this.dialog = true;
    },
    update_staff() {
      this.submit = "loading";
      axios
        .patch("update-staff/" + this.staff_id, this.staff)
        .then((response) => {
          switch (response.data[0]) {
            case 200:
              this.staff.full_name = "";
              this.staff.image = "";
              this.staff.role = null;
              swal("Good job!", "Staff updated successfully!", "success");
              break;
            default:
              swal(
                "Oops!",
                "Sorry something went wrong, please try again!",
                "error"
              );
              break;
          }
          this.submit = false;
          this.dialog = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.submit = false;
          swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
          return error;
        });
      this.get_data();
    },
    onDragStart(index) {
      event.dataTransfer.setData("text/plain", index);
    },
    onDragOver(event) {
      event.preventDefault();
    },
    onDrop(event, targetIndex) {
      const sourceIndex = event.dataTransfer.getData("text/plain");
      const draggedItem = this.staffs[sourceIndex];
      this.staffs.splice(sourceIndex, 1);
      this.staffs.splice(targetIndex, 0, draggedItem);

      this.update_staff_position();
    },
    update_staff_position() {
      this.loading = true;
      axios
        .patch("update-staff-position", { staffs: this.staffs })
        .then((response) => {
          if (response.data[0] == 200) {
            this.get_data();
            swal(response.data[1]);
          }
        })
        .catch((error) => {
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
          return error;
        });
    },
    publish_unpublish_staff(staff_id) {
      axios
        .get("publish-unpublish-staff/" + staff_id)
        .then((response) => {
          if (response.data[0] == 200) {
            swal(response.data[1]);
          }
        })
        .catch((error) => {
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
          return error;
        });
    },
  },
  created() {
    this.get_data();
  },
};
</script>

<style scoped></style>
