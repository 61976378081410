<template>
  <div>
    <div v-if="loading">
      <Preloader />
    </div>
    <div v-else>
      <div style="margin-top: 2rem" v-for="info in news" :key="info.id">
        <v-card elevation="2">
          <v-card-title class="d-block">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div>
                {{ info.title }}
              </div>
              <div
                style="
                  display: flex;
                  justify-content: right;
                  align-items: center;
                "
              >
                <div class="float-right">
                  <router-link :to="'edit-news/' + info.id">
                    <v-btn class="mx-2" fab dark small>
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-btn>
                  </router-link>
                </div>

                <div class="float-right">
                  <v-switch
                    v-model="info.status"
                    @change="publish_unpublish_news(info.id)"
                    inset
                  ></v-switch>
                </div>
              </div>
            </div>
          </v-card-title>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import Preloader from "../universal/Preloader.vue";
export default {
  components: {
    Preloader,
  },
  data: () => ({
    errors: [],
    loading: true,
    news: [],
  }),
  methods: {
    get_news() {
      axios
        .get("get-news")
        .then((response) => {
          this.news = response.data;
          this.loading = false;
        })
        .catch((error) => {
          swal(
            "Oops!",
            "Sorry something went wrong, please try again!",
            "error"
          );
          return error;
        });
    },

    publish_unpublish_news(news_id) {
      axios
        .get("publish-unpublish-news/" + news_id)
        .then((response) => {
          if (response.data[0] == 200) {
            swal(response.data[1]);
            this.get_news();
          }
        })
        .catch((error) => {
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
          return error;
        });
    },
  },
  created() {
    this.get_news();
  },
};
</script>

<style scoped></style>
