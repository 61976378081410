import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/modules/user.js'
import Login from '../views/auth/Login.vue'
// import Register from '../views/auth/Register.vue'
import AdminDashboard from '../views/layouts/AdminDashboard.vue'
import Dashboard from '../components/admin/Dashboard.vue'
import AdminProfile from '../views/auth/Profile.vue';
import Staffs from '../components/admin/Staffs.vue'
import AddAlumni from '../components/admin/Add-Alumni.vue'
import Alumnis from '../components/admin/Alumnis.vue'
import EditAlumni from '../components/admin/Edit-Alumni.vue'
import AddNewsEvents from '../components/admin/Add-News-Events.vue'
import NewsEvents from '../components/admin/News-Events.vue'
import EditNewsEvents from '../components/admin/Edit-News-Events.vue'
import StudentsApplication from '../components/admin/Applications'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/dashboard',
    component: AdminDashboard,
    meta:{requiresAuth:true},
    children:[
      {path:'/dashboard',name:'Dashboard',component:Dashboard},
      {path:'/profile',name:'AdminProfile',component:AdminProfile},
      {path:'/staffs',name:'Staffs',component:Staffs},
      {path:'/add-alumni',name:'AddAlumni',component:AddAlumni},
      {path:'/alumnis',name:'Alumnis',component:Alumnis},
      {path:'/edit-alumni/:id',name:'EditAlumni',component:EditAlumni},
      {path:'/add-news-event',name:'AddNewsEvents',component:AddNewsEvents},
      {path:'/news-event',name:'NewsEvents',component:NewsEvents},
      {path:'/edit-news/:id',name:'EditNewsEvents',component:EditNewsEvents},
      {path:'/students-applications',name:'StudentsApplication',component:StudentsApplication},
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  const protectedRoutes = ['Dashboard','AdminProfile','Staffs','AddAlumni','Alumnis','EditAlumni','AddNewsEvents','NewsEvents','EditNewsEvents','StudentsApplication'];
  if(!store.state.user.token && protectedRoutes.includes(to.name)){
    next({name:'Login'})
  }else if(store.state.user.token && (to.name == 'Login' || to.name == 'Register')){
    next({path:'/dashboard/'});
  }else{
    next();
  }
})

export default router
