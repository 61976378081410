<template>
  <div>
    <!-- <div v-if="main_loading">
      <Preloader />
    </div> -->
    <div>
      <v-container>
        <v-card class="mb-5">
          <v-card-title> Profile </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="user.name"
                  label="Name"
                  required
                ></v-text-field>
                <div
                  v-if="errors.name"
                  v-html="errors.name[0]"
                  style="color: red"
                />
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="user.email"
                  :rules="emailRules"
                  label="Email"
                  required
                ></v-text-field>
                <div
                  v-if="errors.email"
                  v-html="errors.email[0]"
                  style="color: red"
                />
              </v-col>
              <!-- <v-col cols="12" md="4" sm="12">
              <v-text-field
                  v-model="user.phone_number"
                  label="Phone number"
                  required
              ></v-text-field>
              <div
                  v-if="errors.phone_number"
                  v-html="errors.phone_number[0]"
                  style="color: red"
              />
            </v-col> -->
            </v-row>
            <div>
              <v-btn block elevation="2" @click="update_profile"
                >Update Profile</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
        <!--    Update password card-->
        <v-card>
          <v-card-title> Update Password </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" sm="12">
                <v-text-field
                  v-model="password.old_password"
                  label="Old password"
                  required
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  @click:append="show1 = !show1"
                ></v-text-field>
                <div
                  v-if="errors.old_password"
                  v-html="errors.old_password[0]"
                  style="color: red"
                />
              </v-col>
              <v-col cols="12" md="4" sm="12">
                <v-text-field
                  v-model="password.new_password"
                  label="New Password"
                  required
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  @click:append="show1 = !show1"
                ></v-text-field>
                <div
                  v-if="errors.new_password"
                  v-html="errors.new_password[0]"
                  style="color: red"
                />
              </v-col>
              <v-col cols="12" md="4" sm="12">
                <v-text-field
                  v-model="password.new_password_confirmation"
                  label="Password Confirmation"
                  required
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
            </v-row>
            <div>
              <v-btn block elevation="2" @click="update_password"
                >Update Password</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-container>
      <!--    Progress dialog-->
      <v-dialog v-model="progress_dialog" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Working on your request please wait....
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import store from "../../store/modules/user.js";
import axios from "axios";
import swal from "sweetalert";
// import Preloader from "../../components/universal/Preloader.vue";
export default {
  name: "Profile",
  data: () => ({
    progress_dialog: false,
    errors: [],
    main_loading: true,
    user_id: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    user: {
      name: "",
      email: "",
      phone_number: "",
    },
    password: {
      old_password: "",
      new_password: "",
      new_password_confirmation: "",
    },
    show1: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
  }),
  methods: {
    get_user_profile() {
      axios
        .get("profile/" + this.user_id)
        .then((response) => {
          this.user.name = response.data[1].name;
          this.user.email = response.data[1].email;
          this.user.phone_number = response.data[1].phone_number;
          this.main_loading = false;
        })
        .catch((error) => {
          swal.fire(
            "The Internet?",
            "Please check your internet connection and reload the page?",
            "question"
          );
          return error;
        });
    },
    update_profile() {
      this.progress_dialog = true;
      axios
        .patch("update_profile/" + this.user_id, this.user)
        .then((response) => {
          switch (response.data[0]) {
            case 200:
              swal(
                "Profile Updated!",
                "Profile updated successfully!",
                "success"
              );
              this.user.name = response.data[1].name;
              this.user.email = response.data[1].email;
              this.user.phone_number = response.data[1].phone_number;
              //Update user state
              //   console.log(store.state.user.data.name)
              sessionStorage.setItem("data", JSON.stringify(response.data[1]));
              // console.log(sessionStorage.getItem('data'))
              store.state.user.data.name = response.data[1].name;
              store.state.user.data.email = response.data[1].email;
              store.state.user.data.phone_number =
                response.data[1].phone_number;
              break;

            default:
              // this.progress_dialog = false;
              this.errors = response.data.errors;
              swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong, please try again!",
              });
              break;
          }
          this.progress_dialog = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
          this.progress_dialog = false;
          return error;
        });
    },
    update_password() {
      this.progress_dialog = true;
      axios
        .patch("update_password/" + this.user_id, this.password)
        .then((response) => {
          // if (response.data[0] == 200) {
          //   swal("Updated!", "Meal updated successfully!", "success");
          // }
          // console.log(response.data[0])
          switch (response.data[0]) {
            case 200:
              swal("Updated!", response.data[1], "success");
              this.password.old_password = "";
              this.password.new_password = "";
              this.password.new_password_confirmation = "";
              break;
            case 403:
              var error_msg = response.data[1];
              swal({
                icon: "error",
                title: "Oops...",
                text: error_msg,
              });
              break;
          }
          this.progress_dialog = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.progress_dialog = false;
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
          return error;
        });
    },
  },
  created() {
    this.user_id = store.state.user.data.id;
    this.get_user_profile();
  },
  //   components: { Preloader },
};
</script>

<style scoped></style>
