<template>
  <v-app id="inspire">
    <!-- <v-system-bar app>
      <v-spacer></v-spacer>

      <v-icon>mdi-square</v-icon>

      <v-icon>mdi-circle</v-icon>

      <v-icon>mdi-triangle</v-icon>
    </v-system-bar> -->
    <TopAppBar @handeDrawer="drawer = !drawer" />

    <SideNavigationBar :drawer="drawer" />

    <v-main>
      <v-container class="py-8 px-6" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TopAppBar from "../../components/bars/TopAppBar.vue";
import SideNavigationBar from "../../components/bars/SideNavigationBar.vue";
export default {
  data: () => ({
    cards: ["Today", "Yesterday"],
    drawer: true,
  }),
  components: { TopAppBar, SideNavigationBar },
};
</script>
