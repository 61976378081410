<template>
  <div>
    <div
      style="
        min-height: 75vh;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="yellow"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
