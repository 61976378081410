<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <center>
              <img src="/assets/logo.png" alt="" width="100px" />
            </center>
            <div>
              <!-- <v-toolbar dark color="primary">
                <v-toolbar-title>Login form</v-toolbar-title>
              </v-toolbar> -->
              <div>
                <div id="error" class="text-center" style="color: red"></div>
                <form @submit.prevent="login">
                  <div>
                    <v-text-field
                      v-model="auth.email"
                      name="E-mail"
                      label="E-mail"
                      type="email"
                      outlined
                    ></v-text-field>
                    <div
                      v-if="errors.password"
                      v-html="errors.password[0]"
                      style="color: red"
                    />
                  </div>
                  <div>
                    <v-text-field
                      v-model="auth.password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Password"
                      outlined
                      @click:append="show1 = !show1"
                    ></v-text-field>
                    <div
                      v-if="errors.password"
                      v-html="errors.password[0]"
                      style="color: red"
                    />
                  </div>
                  <div>
                    <!-- <v-spacer></v-spacer> -->
                    <!-- <v-btn color="primary" to="/">Login</v-btn> -->
                    <v-layout align-center justify-center>
                      <v-btn
                        :loading="loading"
                        depressed
                        block
                        elevation="2"
                        @click="login()"
                      >
                        LOGIN
                      </v-btn>
                    </v-layout>
                  </div>
                </form>
              </div>
              <v-layout>
                <div>
                  <!-- <router-link to="/register"
                    >Don`t have account Register</router-link
                  > -->
                </div>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import store from "../../store/modules/user";
export default {
  data: () => ({
    loading: null,
    errors: [],
    auth: {
      email: "",
      password: "",
    },
    show1: false,
    // rules: {
    //   required: (value) => !!value || "Required.",
    //   min: (v) => v.length >= 8 || "Min 8 characters",
    //   emailMatch: () => `The email and password you entered don't match`,
    // },
  }),
  methods: {
    login() {
      this.loading = "loading";
      store
        .dispatch("login", this.auth)
        .then((response) => {
          if (response[0] == 401) {
            document.getElementById("error").innerHTML =
              "Wrong email or password";
            this.loading = !this.loading;
          } else {
            this.$router.push({ path: "/dashboard" });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="stylus" scoped></style>
