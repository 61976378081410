<template>
  <div>
    <div v-if="loading">
      <Preloader />
    </div>
    <template v-else>
      <v-card>
        <v-card-title>
          Applications
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="applications" :search="search">
          <template v-slot:item.action="{ item }">
            <v-btn icon @click="show_details(item)">
              <v-icon color="grey lighten-1"> mdi-pencil </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <!-- Dialog -->
      <v-dialog v-model="dialog" persistent max-width="600px" v-if="application">
        <v-card>
          <v-card-title class="text-h5"> Application </v-card-title>

          <v-card-text>
            <table>
              <tr>
                <th style="float: left" colspan="2">
                  <v-chip class="ma-2" color="blue" label text-color="white">
                    <v-icon left> mdi-label </v-icon>
                    Student Informations
                  </v-chip>
                </th>
              </tr>
              <tr>
                <table>
                  <tr>
                    <th style="float: left">Student Name:</th>
                    <td>{{ application.name }}</td>
                  </tr>
                  <tr>
                    <th style="float: left">Student Email:</th>
                    <td>{{ application.email }}</td>
                  </tr>
                  <tr>
                    <th style="float: left">Student Address:</th>
                    <td>{{ application.address }}</td>
                  </tr>
                  <tr>
                    <th style="float: left">Student Phone:</th>
                    <td>{{ application.phone_number }}</td>
                  </tr>
                  <tr>
                    <th style="float: left">Student Date of Birth:</th>
                    <td>{{ application.date_of_birth }}</td>
                  </tr>
                </table>
              </tr>

              <tr>
                <th style="float: left" colspan="2">
                  <v-chip class="ma-2" color="cyan" label text-color="white">
                    <v-icon left> mdi-label </v-icon>
                    School Information:
                  </v-chip>
                </th>
              </tr>
              <tr>
                <table>
                  <tr>
                    <th style="float: left">School:</th>
                    <td>{{ application.school }}</td>
                  </tr>
                  <tr>
                    <th style="float: left">Form Four:</th>
                    <td>{{ application.form_four_accomplishment }}</td>
                  </tr>
                  <tr>
                    <th style="float: left">Form Six:</th>
                    <td>{{ application.form_six_accomplishment }}</td>
                  </tr>
                  <tr>
                    <th style="float: left">Form Six Mock Division:</th>
                    <td>{{ application.mock_division }}</td>
                  </tr>
                </table>
              </tr>

              <tr>
                <th style="float: left" colspan="2">
                  <v-chip class="ma-2" color="primary" label text-color="white">
                    <v-icon left> mdi-label </v-icon>
                    Parent Information:
                  </v-chip>
                </th>
              </tr>
              <tr>
                <table>
                  <tr>
                    <th style="float: left">Parent Name(s):</th>
                    <td>{{ application.parents_names }}</td>
                  </tr>
                  <tr>
                    <th style="float: left">Parent Occupation:</th>
                    <td>{{ application.occupation }}</td>
                  </tr>
                  <tr>
                    <th style="float: left">Parent Address:</th>
                    <td>{{ application.parent_address }}</td>
                  </tr>
                </table>
              </tr>

              <tr>
                <th style="float: left" colspan="2">
                  <v-chip class="ma-2" color="green" label text-color="white">
                    <v-icon left> mdi-label </v-icon>
                    Course and Interview:
                  </v-chip>
                </th>
              </tr>
              <tr>
                <table>
                  <tr v-if="application.course">
                    <th style="float: left">Course:</th>
                    <td>{{ application.course.title }}</td>
                  </tr>
                  <tr>
                    <th style="float: left">Interview Date:</th>
                    <td>{{ application.interiew_date }}</td>
                  </tr>
                  <tr>
                    <th style="float: left">Interview Time:</th>
                    <td>{{ application.interiew_time }}</td>
                  </tr>
                  <tr>
                    <th style="float: left">Interview Type:</th>
                    <td>{{ application.interview_type }}</td>
                  </tr>
                </table>
              </tr>
            </table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog -->
    </template>
  </div>
</template>

<script>
import Preloader from "../universal/Preloader.vue";
import axios from "axios";
import swal from "sweetalert";
export default {
  components: {
    Preloader,
  },
  data() {
    return {
      loading: true,
      search: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Phone Number", value: "phone_number" },
        { text: "Email", value: "email" },
        { text: "Parent", value: "parents_names" },
        { text: "Interview Date", value: "interiew_date" },
        { text: "Interview Time", value: "interiew_time" },
        { text: "Interview Type", value: "interview_type" },
        { text: "Action", value: "action" },
      ],
      applications: [],
      application: {},
      dialog: false,
    };
  },
  methods: {
    get_applications() {
      axios
        .get("get-students-applications")
        .then((response) => {
          this.applications = response.data;
          this.loading = false;
        })
        .catch((error) => {
          swal(
            "Oops!",
            "Sorry something went wrong, please try again!",
            "error"
          );
          return error;
        });
    },

    show_details(application) {
      this.dialog = true;
      this.application = application;
    },
  },
  created() {
    this.get_applications();
  },
};
</script>

<style>
</style>