import axios from "axios";
import Vuex from 'vuex'
import vue from 'vue'
// import axiosClient from '../../axios'

vue.use(Vuex)

// const baseUrl = 'http://127.0.0.1:8000/api/';

const state = {
    user:{
        data:JSON.parse(sessionStorage.getItem('data')),
        token:sessionStorage.getItem('TOKEN')
    },
};

const getters = {
    // authenticatedUser:state=>state.user
};

const actions = {
    async login({commit},user_data){
        const response = await axios.post('login',user_data);
        if (response.data[0] == 401) {
            // alert(response.data[1])
            return response.data;
        }
        axios.defaults.headers = {
            "Authorization":`Bearer ${response.data[1]}`,
            "Accept":'application/json'
            }
        commit("setUserData",response.data)
        return response.data
    },
    async register({commit},user_data){
        const response = await axios.post('register',user_data);
        axios.defaults.headers = {
            "Authorization":`Bearer ${response.data[1]}`,
            "Accept":'application/json'
            }
        commit("setUserData",response.data)
    },
    async logout({commit}){
        const response = await axios.post('logout');
        axios.defaults.headers = {};
        commit('logout')
        return response;
    }
};

const mutations = {
    setUserData:(state,userData)=>{
        // state.user.data = sessionStorage.setItem('data',userData[0]);
        state.user.data = userData[0]
        state.user.token = userData[1]
        sessionStorage.setItem('data',JSON.stringify(userData[0]))
        sessionStorage.setItem('TOKEN',userData[1]);
        // console.log(userData[0])
        // console.log(state.user.data)
    },
    logout:(state)=>{
        state.user.data = {};
        state.user.token = '';
        sessionStorage.removeItem('TOKEN');
        sessionStorage.removeItem('data');
    }
};

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})