<template>
  <div>
    <div v-if="loading">
      <Preloader />
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            label="Title"
            v-model="event.title"
            required
            outlined
          ></v-text-field>
          <div
            v-if="errors.title"
            v-html="errors.title[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <ckeditor v-model="event.description"></ckeditor>
          <div
            v-if="errors.description"
            v-html="errors.description[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-file-input
            outlined
            truncate-length="15"
            label="Select Image (s)"
            @change="set_event_images"
            accept="image/*"
            multiple
          ></v-file-input>
          <div
            v-if="errors.images"
            v-html="errors.images[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-btn block :loading="submit" @click="add_news_events"> Save </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import Preloader from "../universal/Preloader.vue";
export default {
  components: {
    Preloader,
  },
  data: () => ({
    errors: [],
    loading: false,
    submit: false,
    event: {
      title: "",
      description: "",
      images: [],
    },
  }),
  methods: {
    set_event_images(event) {
      for (let i = 0; i < event.length; i++) {
        let file = event[i];
        let render = new FileReader();
        render.onloadend = () => {
          this.event.images.push({ image: render.result });
        };
        render.readAsDataURL(file);
      }
    },
    add_news_events() {
      this.errors = [];
      this.submit = "loading";
      axios
        .post("add-news-event", this.event)
        .then((response) => {
          switch (response.data[0]) {
            case 200:
              this.event.title = "";
              this.event.description = "";
              this.event.images = [];
              this.errors = [];
              this.submit = false;
              swal("Good job!", "Saved successfully!", "success");
              break;
            default:
              swal(
                "Oops!",
                "Sorry something went wrong, please try again!",
                "error"
              );
              this.submit = false;
              break;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.submit = false;
          swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
          return error;
        });
    },
  },
};
</script>

<style scoped></style>
