<template>
  <div>
    <div v-if="loading">
      <Preloader />
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            label="Full name"
            v-model="alumni.full_name"
            required
            outlined
          ></v-text-field>
          <div
            v-if="errors.full_name"
            v-html="errors.full_name[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            label="Email"
            v-model="alumni.email"
            required
            outlined
          ></v-text-field>
          <div
            v-if="errors.email"
            v-html="errors.email[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            label="Title"
            v-model="alumni.title"
            required
            outlined
          ></v-text-field>
          <div
            v-if="errors.title"
            v-html="errors.title[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            label="Personal Website"
            v-model="alumni.website"
            required
            outlined
          ></v-text-field>
          <div
            v-if="errors.website"
            v-html="errors.website[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            label="Instagram"
            v-model="alumni.instagram"
            required
            outlined
          ></v-text-field>
          <div
            v-if="errors.instagram"
            v-html="errors.instagram[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            label="Twitter"
            v-model="alumni.twitter"
            required
            outlined
          ></v-text-field>
          <div
            v-if="errors.twitter"
            v-html="errors.twitter[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            label="Linked in"
            v-model="alumni.linked_in"
            required
            outlined
          ></v-text-field>
          <div
            v-if="errors.linked_in"
            v-html="errors.linked_in[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            label="Facebook"
            v-model="alumni.facebook"
            required
            outlined
          ></v-text-field>
          <div
            v-if="errors.facebook"
            v-html="errors.facebook[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            label="Course Year"
            v-model="alumni.course_year"
            required
            outlined
          ></v-text-field>
          <div
            v-if="errors.course_year"
            v-html="errors.course_year[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-select
            v-model="alumni.course_four_six"
            :items="course_nos"
            item-text="title"
            item-value="id"
            label="Course four / course six"
            required
            outlined
          ></v-select>
          <div
            v-if="errors.facebook"
            v-html="errors.facebook[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-file-input
            outlined
            truncate-length="15"
            label="Select Cover Image"
            @change="set_cover_image"
            accept="image/*"
          ></v-file-input>
          <div
            v-if="errors.cover_image"
            v-html="errors.cover_image[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-file-input
            outlined
            truncate-length="15"
            label="Select Profile Image"
            @change="set_profile_image"
            accept="image/*"
          ></v-file-input>
          <div
            v-if="errors.profile_image"
            v-html="errors.profile_image[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <ckeditor v-model="alumni.description"></ckeditor>
          <div
            v-if="errors.description"
            v-html="errors.description[0]"
            style="color: red"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-btn block :loading="submit" @click="add_alumni_info"> Save </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import Preloader from "../universal/Preloader.vue";
export default {
  components: {
    Preloader,
  },
  data: () => ({
    submit: false,
    loading: false,
    errors: [],
    course_nos: [
      { id: 4, title: "Course Four" },
      { id: 6, title: "Course Six" },
    ],
    alumni: {
      full_name: "",
      email: "",
      title: "",
      website: "",
      instagram: "",
      twitter: "",
      linked_in: "",
      facebook: "",
      description: "",
      profile_image: "",
      cover_image: "",
      course_year: "",
      course_four_six: "",
    },
  }),
  methods: {
    set_cover_image(event) {
      let file = event;
      let render = new FileReader();
      render.onloadend = () => {
        this.alumni.cover_image = render.result;
      };
      render.readAsDataURL(file);
    },
    set_profile_image(event) {
      let file = event;
      let render = new FileReader();
      render.onloadend = () => {
        this.alumni.profile_image = render.result;
      };
      render.readAsDataURL(file);
    },
    add_alumni_info() {
      this.loading = true;
      this.submit = "loading";
      axios
        .post("add-alumni-info", this.alumni)
        .then((response) => {
          switch (response.data[0]) {
            case 200:
              this.alumni.full_name = "";
              this.alumni.email = "";
              this.alumni.title = "";
              this.alumni.website = "";
              this.alumni.instagram = "";
              this.alumni.twitter = "";
              this.alumni.linked_in = "";
              this.alumni.facebook = "";
              this.alumni.description = "";
              this.alumni.profile_image = "";
              this.alumni.cover_image = "";
              this.alumni.course_year = "";
              this.alumni.course_four_six = "";
              this.submit = false;
              swal("Good job!", "Alumni added successfully!", "success");
              break;
            default:
              swal(
                "Oops!",
                "Sorry something went wrong, please try again!",
                "error"
              );
              break;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.submit = false;
          swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
          return error;
        });
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
