<template>
  <div>
    <div v-if="loading">
      <Preloader />
    </div>
    <div
      v-else
      style="margin-top: 2rem"
      v-for="(alumni, index) in alumnis"
      :key="alumni.id"
      :ref="`alumni-${index}`"
      @dragstart="onDragStart(index)"
      @dragover="onDragOver"
      @drop="onDrop($event, index)"
      draggable="true"
    >
      <v-card elevation="2">
        <v-card-title class="d-block">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div>
              {{ alumni.name }} <br />
              <small>{{ alumni.title }}</small>
            </div>
            <div
              style="display: flex; justify-content: right; align-items: center"
            >
              <div class="float-right">
                <router-link :to="'edit-alumni/' + alumni.id">
                  <v-btn class="mx-2" fab dark small>
                    <v-icon dark> mdi-pencil </v-icon>
                  </v-btn>
                </router-link>
              </div>

              <div class="float-right">
                <v-switch
                  v-model="alumni.status"
                  @change="publish_unpublish_alumni(alumni.id)"
                  inset
                ></v-switch>
              </div>
            </div>
          </div>
        </v-card-title>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import Preloader from "../universal/Preloader.vue";
export default {
  components: {
    Preloader,
  },
  data: () => ({
    loading: true,
    alumnis: [],
  }),
  methods: {
    get_alumnis() {
      axios
        .get("get-alumnis")
        .then((response) => {
          this.alumnis = response.data;
          this.loading = false;
        })
        .catch((error) => {
          swal(
            "Oops!",
            "Sorry something went wrong, please try again!",
            "error"
          );
          return error;
        });
    },
    publish_unpublish_alumni(alumni_id) {
      axios
        .get("publish-unpublish-alumni/" + alumni_id)
        .then((response) => {
          if (response.data[0] == 200) {
            swal(response.data[1]);
          }
        })
        .catch((error) => {
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
          return error;
        });
    },
    onDragStart(index) {
      event.dataTransfer.setData("text/plain", index);
    },
    onDragOver(event) {
      event.preventDefault();
    },
    onDrop(event, targetIndex) {
      const sourceIndex = event.dataTransfer.getData("text/plain");
      const draggedItem = this.alumnis[sourceIndex];
      this.alumnis.splice(sourceIndex, 1);
      this.alumnis.splice(targetIndex, 0, draggedItem);

      this.update_alumni_position();
    },
    update_alumni_position() {
      this.loading = true;
      axios
        .patch("update-alumni-position", { alumnis: this.alumnis })
        .then((response) => {
          if (response.data[0] == 200) {
            this.get_alumnis();
            swal(response.data[1]);
          }
        })
        .catch((error) => {
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
          return error;
        });
    },
  },
  created() {
    this.get_alumnis();
  },
};
</script>

<style scoped></style>
