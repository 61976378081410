<template>
  <div>
    <div v-if="loading">
      <Preloader />
    </div>
    <v-row v-else>
      <v-col cols="12" md="3">
        <v-card elevation="2">
          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-card-title class="text-h5">Users</v-card-title>

                <v-card-subtitle>All Users in system</v-card-subtitle>
              </v-col>
              <v-col cols="12" md="4">
                <v-container>
                  <div>{{ data[0] }}</div>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card elevation="2">
          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-card-title class="text-h5">Staffs</v-card-title>

                <v-card-subtitle>Tfl Staffs</v-card-subtitle>
              </v-col>
              <v-col cols="12" md="4">
                <v-container>
                  <div>{{ data[1] }}</div>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card elevation="2">
          <v-container>
            <v-row>
              <v-col cols="12" md="8">
                <v-card-title class="text-h5">Alumni</v-card-title>

                <v-card-subtitle>Tfl Alumni</v-card-subtitle>
              </v-col>
              <v-col cols="12" md="4">
                <v-container>
                  <div>{{ data[2] }}</div>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Preloader from "../universal/Preloader.vue";
import axios from "axios";
import swal from "sweetalert";
export default {
  components: {
    Preloader,
  },
  data: () => ({
    loading: true,
    data: [],
  }),
  methods: {
    get_dashboard_data() {
      axios
        .get("get-dashboard-data")
        .then((response) => {
          this.data = response.data;
          this.loading = false;
        })
        .catch((error) => {
          swal(
            "Oops!",
            "Sorry something went wrong, please try again!",
            "error"
          );
          return error;
        });
    },
  },
  created() {
    this.get_dashboard_data();
  },
};
</script>

<style lang="stylus" scoped></style>
